@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
@font-face{
  font-family:bariol-bold-webfont;
  src:url(/static/media/bariol-bold-webfont.dc67f52f.ttf);

  font-family:bariol-regular-webfont;
  src:url(/static/media/bariol-regular-webfont.256143b2.ttf);
}

*{
  box-sizing:border-box;
}

body{
  margin:0;
  font-family:'bariol-regular-webfont';
  background-color:#EEE;
}
