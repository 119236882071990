@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@font-face{
  font-family:bariol-bold-webfont;
  src:url(./assets/fontes/bariol-bold-webfont.ttf);

  font-family:bariol-regular-webfont;
  src:url(./assets/fontes/bariol-regular-webfont.ttf);
}

*{
  box-sizing:border-box;
}

body{
  margin:0;
  font-family:'bariol-regular-webfont';
  background-color:#EEE;
}